.loading {
    margin-bottom: 15px;
}

.fadeIn1 {
    animation: fadeIn 4s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }