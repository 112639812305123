@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');

@font-face {
  font-family: 'street_punks_markerregular';
  src: url('./fonts/streetpunksmarkerregular-webfont.woff2') format('woff2'),
       url('./fonts/streetpunksmarkerregular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

:root {
  --body-fonts:'Roboto Mono', monospace;
  --header-fonts:'street_punks_markerregular';
  --color-bg: #1B1B1B;
  --color-text: #FFFFFF;
}


