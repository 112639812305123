/* .feelingtText  {
    animation: fade 0.9s ease-in;
}

@keyframes fade {
    0% {
        opacity: 0;
    }
} */

.fadeIn {
    animation: fadeInOut 4s linear 1 forwards;
  }

  .feelingtText {
        animation:fadeInOut 6s linear 1 forwards;
        animation-delay: 4s;
        opacity: 0;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .introContainer {
      position: relative;
  }

  /* .introContainer img {
      position: relative;
  } */

  .introContainer h1 {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    margin-top: -80px;
}