.rf_button-container {
   height: 100%;
}

.rf_button-container button {
    display: block;
}

span {
  font-size: 20px;
  line-height: 60px;
}

button {
    outline: none;
    border: none;
    min-width: 137px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    padding: 11px 25px;
    cursor: pointer;
    margin: 10px;
}

.btns {
  height: 40px;
}

.mellow {
    background-color: #F3F4F5;
    color: black;
  }
  
  .happy {
    background-color: #CCCCCC;
  }
  
  .excited {
    background-color: #749D69;
  }
  
  .bored {
    background-color: #6B529F;
  }
  
  .sad {
    background-color: #346BB0;
  }
  
  .annoyed {
    background-color: #A2509B;
  }
  
  .angry {
    background-color: #E72E2F;
  }
  
  .shutdown {
    background-color: #6A7A8A;
  }
  
  .fadeIn1 {
    animation: fadeIn 4s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }