.rf__upload-container button {
    background: none;
}

.rf__preview-image img {
    max-width: 253px;
    height: auto;
}

.pointer {
    cursor: pointer;
}

.download {
    background: #F3F4F5 !important;
    border-radius: 5px;
    text-align: center;
    color: black;
    cursor: pointer;
    margin-top: 30px;
}

.downlaod__container {
    max-width: 253px;
    padding: 5px;
}

.downlaod__container h1 {
  margin-bottom: 10px;
  margin-top: 10px;
}

canvas {
    border-radius: 5px;
}

p {
    text-transform: uppercase;
}

.social__btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-around;
    max-width: 230px;
    margin: auto;
}

.social__btn .react-share__ShareButton {
 min-width: 0px!important;
 margin: none !important;
 height: 40px !important;
}

a {
    cursor: pointer;
}

.fadeIn1 {
    animation: fadeIn 4s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }