input { 
    background-color: transparent;
    color: white;
    border: 1px solid #F3F4F5;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    padding: 10px 20px 10px 10px;
    text-align: center;
    margin-top: 31px;
}

/* input[type=submit] {
    background: #F3F4F5;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    color: black;
}

input[type=button] {
    background: #F3F4F5;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    color: black;
    cursor: pointer;
} */

input[type="submit"]{
    -webkit-appearance: none;
    -moz-appearance: none;
    }

    input[type="button"]{
        -webkit-appearance: none;
        -moz-appearance: none;
        }


.inputBtn {
    background: #F3F4F5;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    color: black;
    cursor: pointer;
}


::placeholder {
    color:  #F3F4F5;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.fadeIn1 {
    animation: fadeIn 4s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .loadH1 {
      text-transform: uppercase;
  }