* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  /* outline: 1px solid red; */
}

/* body > #root > div {
  height: 100vh;
} */

.center {
  height: 100vh;
  justify-content: center;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  flex-direction: column; 
}

body {
  background: var(--color-bg);
  color: var(--color-text);
  font-family: var(--body-fonts);
  text-align: center;
  height: 100%;
}

p {
  font-family: var(--body-fonts) ;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
}

h1 {
  font-size: 50px;
}

h1, h2, h3 {
  font-family: var(--header-fonts);
  line-height: 120%;
  letter-spacing: 2px;
}

a { 
  color: unset;
  text-decoration: none;
}

.background__bg {
  background-color: var(--color-bg);
}

.section__padding {
  padding: 4rem 6rem;
}

.section__padding-sm {
  padding: 2rem 2rem;
}

.section__padding-large {
  padding: 6rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.column__padding {
  padding: 2em;
}

/* animation */
.scale-up-center {
-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
* Generated by Animista on 2022-3-4 12:4:14
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation scale-up-center
* ----------------------------------------
*/
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@media screen and (max-width: 700px) {
  .section__padding {
      padding: 4rem;
  }
  
  .section__margin {
      margin: 4rem;
  }
}

@media screen and (max-width: 500px) {
  .section__padding {
      padding: 4rem 2rem;
  }
  
  .section__margin {
      margin: 4rem 2rem;
  }

  .column__padding {
    padding: 2em 0;
  }
  
  .h1Small {
    font-size: 28px;
  }

}